import { graphql, Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Container from '../components/Container'
import InPageNav from '../components/InPageNav'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import SEO from '../components/SEO'

const VIMEO_VIDEO_ID = '325731697'

const AfterParallaxWrap = styled.div`
  position: relative;
  z-index: 2;
  background-color: white;
`

const SectionInner = styled.div``

const SectionHeader = styled.div`
  text-align: center;

  h1 {
    margin-bottom: 10px;
  }
`

const TasksContainer = styled.ul`
  margin: 30px auto;

  li {
    + li {
      margin-top: 20px;
    }
  }
`

const TaskItemWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 12px;
`

const ResponsiveVideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 40px;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const GettingStarted = ({ data }) => {
  const contentfulTasks = data.allContentfulNewArtistTask.edges.map(
    ({ node }) => ({ ...node })
  )
  contentfulTasks.map(task => Object.assign(task, { completed: false }))

  const [tasks, setTasks] = useState(contentfulTasks)

  const LOCALSTORAGE_KEY = 'taskCompletionList'
  useEffect(() => {
    function retrieveFromLocalStorage() {
      const duplicateTaskList = [...tasks]
      const localStorageTasks =
        JSON.parse(window.localStorage.getItem(LOCALSTORAGE_KEY)) || []

      for (const localStorageTask of localStorageTasks) {
        const taskIndex = duplicateTaskList.findIndex(task => {
          return localStorageTask.id === task.id
        })
        if (taskIndex !== -1) {
          duplicateTaskList[taskIndex].completed = localStorageTask.completed
        }
      }
      setTasks(duplicateTaskList)
    }

    retrieveFromLocalStorage()
  }, [])

  const handleTaskChange = (e, id) => {
    const taskList = tasks.map(task => {
      if (task.id === id) {
        task.completed = !task.completed
      }
      return task
    })

    const trackLocalStorageTasks = taskList.map(({ id, completed }) => ({
      id,
      completed,
    }))
    window.localStorage.setItem(
      LOCALSTORAGE_KEY,
      JSON.stringify(trackLocalStorageTasks)
    )
    setTasks(taskList)
  }

  return (
    <Layout>
      <SEO />

      <PageHeader
        image={data.allContentfulAsset.edges[0].node}
        title="Training"
      />
      <AfterParallaxWrap id="stickyNavContainer">
        <InPageNav id="stickyNav">
          <Link to="/training/" activeClassName="active-page-link">
            Getting Started
          </Link>
          <Link
            to="/training/iiid-foundation/"
            activeClassName="active-page-link"
          >
            IIID Foundation
          </Link>
          <Link to="/training/product/" activeClassName="active-page-link">
            Product
          </Link>
          <Link to="/training/website/" activeClassName="active-page-link">
            Website
          </Link>
        </InPageNav>
        <div className="section">
          <Container wrap="true" afterStickyNav="true">
            <SectionHeader>
              <h1 className="headline--large">
                Welcome to the{' '}
                <span className="inline-block">Maskcara Family!</span>
              </h1>
              <p className="headline--intro">
                Get started by watching the video and completing the checklist
                below.
              </p>
            </SectionHeader>
            <SectionInner>
              <ResponsiveVideoWrapper>
                <iframe
                  src={`https://player.vimeo.com/video/${VIMEO_VIDEO_ID}?color=DFD0D2&title=0&byline=0&portrait=0`}
                  width="640"
                  height="360"
                  frameBorder="0"
                  webkitallowfullscreen=""
                  mozallowfullscreen=""
                  allowFullScreen=""
                />
              </ResponsiveVideoWrapper>
              <h2 className="section-title">New Artist Checklist</h2>
              <TasksContainer>
                {tasks.map(task => (
                  <li key={task.id}>
                    <TaskItemWrapper>
                      <input
                        id={task.id}
                        type="checkbox"
                        checked={task.completed}
                        onChange={e => handleTaskChange(e, task.id)}
                      />
                      <label htmlFor={task.id} />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: task.body.childMarkdownRemark.html,
                        }}
                      />
                    </TaskItemWrapper>
                  </li>
                ))}
              </TasksContainer>
            </SectionInner>
          </Container>
        </div>
      </AfterParallaxWrap>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulNewArtistTask(sort: { fields: [order], order: [ASC] }) {
      edges {
        node {
          id
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulAsset(filter: { title: { eq: "news-hero-image" } }) {
      edges {
        node {
          title
          fluid(quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default GettingStarted
